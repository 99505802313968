import React, {useState, useEffect, useRef} from 'react'
import ReactDOM from 'react-dom'
import "./frontend.scss"

import {Modal, Icon} from "@wordpress/components"
import { array } from 'prop-types'


const divsToUpdate = document.querySelectorAll(".toms-image-slider")

divsToUpdate.forEach(function(div) {
  const data = JSON.parse(div.querySelector("pre").innerHTML)
  if(data.imageSlider !== false){
    ReactDOM.render(<TomSImageSliderFrontend {...data} />, div)
    div.classList.remove("toms-image-slider")
  }
})

function TomSImageSliderFrontend(slides) {
    var defaultImageFromPHP = tomsSlieshowDefaultFromPHP.defaultImage
    if(!!(slides.galleryImages)){
        const [current, setCurrent] = useState(0)
        const [currentImg, setCurrentImg] = useState(slides.galleryImages[0])
        const length = slides.galleryImages.length
        const slideImg = slides.galleryImages

        const nextSlide = () => {
            setCurrent(current === length - 1 ? 0 : current + 1),
            setCurrentImg(slideImg[current === length - 1 ? 0 : current + 1])

            isOpen && (setModalImg(slideImg[current === length - 1 ? 0 : current + 1]))
        }

        const prevSlide = () => {
            setCurrent(current === 0 ? length - 1 : current - 1),
            setCurrentImg(slideImg[current === 0 ? length - 1 : current - 1])

            isOpen && (setModalImg(slideImg[current === 0 ? length - 1 : current - 1]))
        }

        //自动滑动 autoplay
        const timeoutRef = useRef(null);
        function resetTimeout() { /*清空计时函数*/
            if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
            }
        }
        useEffect(() => {
            if( slides.autoPlay !== false && length >=1 ){
                resetTimeout(); /*清空计时*/

                timeoutRef.current = setTimeout( () => {
                    setCurrent(current === length - 1 ? 0 : current + 1),
                    setCurrentImg(slideImg[current === length - 1 ? 0 : current + 1])
                }, 3600 )

                return () => {
                    resetTimeout(); /*返回清空计时*/
                }
            }
        }, [current])

        //图片弹窗
        const [ isOpen, setOpen ] = useState( false );
        const openModal = () => { slides.sliderModal !== false && setOpen( true ) };
        const closeModal = () => setOpen( false );

        const [modalCurrent, setModalCurrent] = useState(0)
        const [modalImg, setModalImg] = useState(slides.galleryImages[0])

        const modalNextSlide = () => {
            setModalCurrent(modalCurrent === length - 1 ? 0 : modalCurrent + 1)
            setModalImg(slideImg[modalCurrent === length - 1 ? 0 : modalCurrent + 1])
        }

        const modalPrevSlide = () => {
            setModalCurrent(modalCurrent=== 0 ? length - 1 : modalCurrent - 1)
            setModalImg(slideImg[modalCurrent === 0 ? length - 1 : modalCurrent - 1])
        }

        //控制显示缩略图
        const [showState, setShowState] = useState({shown:true})
        function showThumnbail(){
            return(
                setShowState({shown: !showState.shown})
            )
        }

        if(!Array.isArray(slides.galleryImages)){ /*判断图片的值是否小于0 或者是数组*/
            return null;
        }
    return(
        <>
        <section className="toms-image-slider-preview">
            <div className="toms-image-slider-image">
                <span className='left-arrow' onClick={prevSlide}>
                    <svg t="1632461798804" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="8849" width="32" height="32">
                        <path d="M804.157345 1024H596.658663c-9.595315 0-18.790825-4.297901-24.787897-11.794241L247.229283 612.600878c-47.776672-58.771303-47.776672-142.93021 0-201.701512L571.470961 11.794241c6.097023-7.49634 15.192582-11.794241 24.787897-11.794241h206.799024c6.69673 0 10.494876 7.796193 6.196974 12.993655L436.936652 471.369839c-19.090678 23.488531-19.090678 57.172084 0 80.760566l373.417667 458.775988c4.19795 5.297413 0.499756 13.093607-6.196974 13.093607z" p-id="8850"></path>
                    </svg>
                </span>

                <div className="toms-image-slider-active-image" onClick={ openModal } >
                    { /*slides.galleryImages.map(
                        function (img, index){
                            return (
                                <>
                                    <div className={index === current ? 'toms-image-slider-slide toms-image-slider-slide-active' : 'toms-image-slider-slide'} key={index} >
                            {index === current && !!(img) &&( */}
                            {!!(slideImg[current]) ? (
                                <img src={slideImg[current]} className="toms-image-slider-gellery-image" width="200" height="200" />
                            ) : (
                                <img src={defaultImageFromPHP} className="toms-image-slider-gellery-image" width="200" height="200" />
                            )}
                                        {/*)}
                                    </div>
                                </>
                            )}
                            )*/}
                </div>

                <span className='right-arrow' onClick={nextSlide}>
                    <svg t="1632461740711" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="8565" width="32" height="32">
                        <path d="M776.270961 612.600878l-324.641483 399.604881c-6.097023 7.49634-15.192582 11.794241-24.787897 11.794241H219.44285c-6.69673 0-10.494876-7.796193-6.196974-13.093607l373.317716-458.875939c19.090678-23.488531 19.090678-57.172084 0-80.760566L214.145437 12.993655c-4.297901-5.197462-0.499756-12.993655 6.196974-12.993655h206.799024c9.595315 0 18.790825 4.297901 24.787896 11.794241l324.34163 399.105125c47.776672 58.771303 47.776672 142.93021 0 201.701512z" p-id="8566"></path>
                    </svg>
                </span>
            </div>
            {slides.thumnbail !== false && (
            <div className="toms-image-slider-thumnbail">
                {slides.galleryImages.map(
                    function (slide, index){
                        if(index >= 0){
                        return(
                            <span className="toms-image-slider-thumnbail-image">
                                {!!(slide) &&(
                                <img src={slide} className={index === current ? 'toms-image-slider-gellery-thumnbail-image toms-image-slider-gellery-thumnbail-image-active' : 'toms-image-slider-gellery-thumnbail-image'} width="200" height="200" onClick={() => { setCurrentImg(slide), setCurrent(index) } } />
                                )}
                            </span>
                        )}
                })}
            </div>
            )}
            { isOpen && (
                <Modal className="toms-image-slider-modal-block" overlayClassName="toms-image-slider-modal-overlay" onRequestClose={ closeModal } style={{backgroundImage: `url(${!!(slideImg[modalCurrent]) ? (slideImg[modalCurrent]) : '' })`}}>
                    <div className="toms-image-slider-modal-block">
                        {/*<div className="toms-image-slider-modal-main">
                        </div>*/}
                            <span className='modal-left-arrow' onClick={modalPrevSlide}>
                                <Icon
                                    icon={ () => (
                                        <svg t="1632461688518" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="8373" width="64" height="64">
                                            <path d="M804.157345 1024H596.658663c-9.595315 0-18.790825-4.297901-24.787897-11.794241L247.229283 612.600878c-47.776672-58.771303-47.776672-142.93021 0-201.701512L571.470961 11.794241c6.097023-7.49634 15.192582-11.794241 24.787897-11.794241h206.799024c6.69673 0 10.494876 7.796193 6.196974 12.993655L436.936652 471.369839c-19.090678 23.488531-19.090678 57.172084 0 80.760566l373.417667 458.775988c4.19795 5.297413 0.499756 13.093607-6.196974 13.093607z" p-id="8374"></path>
                                        </svg>
                                    )}
                                />
                            </span>
                            {/*<div className="toms-image-slider-modal">
                            slides.map(
                                function (slide, index){
                                    return(
                                        <div className="toms-image-slider-modal">
                                        {index === modalCurrent && (
                                                <img src={modalImg} className="toms-image-slider-modal-image" width="200" height="200" />
                                        )}
                                        </div>
                                    )
                            })
                            </div>*/}
                        
                        {slides.thumnbail !== false && (
                        <>
                            <span  className="toms-image-slider-modal-show-thumnbail-icon" onClick={showThumnbail}>
                                <Icon
                                    icon={
                                        <svg t="1632467128148" className="thumnbail-icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="15012" width="48" height="48">
                                            <path d="M992 96H160a32 32 0 0 0-32 32v96H32a32 32 0 0 0-32 32v640a32 32 0 0 0 32 32h832a32 32 0 0 0 32-32v-96h96a32 32 0 0 0 32-32V128a32 32 0 0 0-32-32zM832 288v506.416l-171.168-146.72a32 32 0 0 0-37.296-3.136l-137.056 82.24-206.4-235.872a32.048 32.048 0 0 0-23.008-10.912 32.192 32.192 0 0 0-23.68 9.36L64 658.752V288h768zM64 749.248l190.432-190.432 201.488 230.272a31.952 31.952 0 0 0 40.544 6.352l140.224-84.128L814.832 864H64v-114.752zM960 736h-64V256a32 32 0 0 0-32-32H192v-64h768v576z" p-id="15013"></path><path d="M656 576c61.744 0 112-50.256 112-112s-50.256-112-112-112-112 50.256-112 112 50.256 112 112 112z m0-160c26.464 0 48 21.536 48 48s-21.536 48-48 48-48-21.536-48-48 21.536-48 48-48z" p-id="15014"></path>
                                        </svg>
                                    }
                                />
                            </span>
                            {showState.shown === true &&
                            <div className="toms-image-slider-modal-thumnbail">
                                {slides.galleryImages.map(
                                    function (slide, index){
                                        if(index >= 0){
                                        return(
                                            <span className="toms-image-slider-modal-thumnbail-image">
                                                <img src={slide} className={index === modalCurrent ? 'toms-image-slider-gellery-modal-thumnbail-image toms-image-slider-gellery-modal-thumnbail-image-active' : 'toms-image-slider-gellery-modal-thumnbail-image'} width="200" height="200" onClick={() => { setModalImg(slide), setModalCurrent(index) } } />
                                            </span>
                                        )}
                                })}
                            </div>
                            }
                        </>
                        )}
                        <span className='modal-right-arrow' onClick={modalNextSlide}>
                            <Icon
                                icon={ () => (
                                    <svg t="1632461620854" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="8135" width="64" height="64">
                                        <path d="M776.270961 612.600878l-324.641483 399.604881c-6.097023 7.49634-15.192582 11.794241-24.787897 11.794241H219.44285c-6.69673 0-10.494876-7.796193-6.196974-13.093607l373.317716-458.875939c19.090678-23.488531 19.090678-57.172084 0-80.760566L214.145437 12.993655c-4.297901-5.197462-0.499756-12.993655 6.196974-12.993655h206.799024c9.595315 0 18.790825 4.297901 24.787896 11.794241l324.34163 399.105125c47.776672 58.771303 47.776672 142.93021 0 201.701512z" p-id="8136"></path>
                                    </svg>
                                )} 
                            />
                        </span>
                    </div>
                </Modal>)
            }
        </section>
        </>
    )
    }else{
        return(
            <div className="toms-image-slider-empty">
                <img src={defaultImageFromPHP} className="toms-image-slider-gellery-image" width="200" height="200" />
            </div>
        )
    }
}